import React from "react";
//import DailyWordsSheet from "./DailyWordsSheet";
import Main from "./Main";
import WordsSearch from "./WordsSearch";

const Home = () => {
  return (
    <div>
      <Main />
      <WordsSearch />
      {/*<DailyWordsSheet />*/}
    </div>
  );
};

export default Home;
