import React from "react";

const Contact = () => {
  return (
    <div
      style={{
        minHeight: "100vh",
        width: "auto",
        margin: "100px auto",
        textAlign: "center",
        fontSize: "24px",
      }}
    >
      <p>
        Owner of a software company that develops Finance and Language related
        applications.
      </p>
      <button
        style={{
          fontSize: "36px",
          padding: "20px",
          backgroundColor: "gold",
          borderRadius: ".4em",
        }}
      >
        <a
          style={{ textDecoration: "none", color: "black" }}
          href="https:/www.richardiriondo.com"
        >
          www.richardiriondo.com
        </a>
      </button>
    </div>
  );
};

export default Contact;
