import React from "react";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <div className="myFooter">
      <ul>
        <li>Home</li>
        <li>About</li>
        <li className="ricLink">
          <a
            style={{
              textDecoration: "none",
              verticalAlign: "middle",
              lineHeight: "160px",
              color: "darkgray",
            }}
            href="www.richardiriondo.com"
          >
            www.richardiriondo.com
          </a>
        </li>
        <li>Contact</li>
        <li>Copyright © {year} </li>
      </ul>
    </div>
  );
};

export default Footer;
