import React from "react";
import BuildSopaLetrasJson from "./BuildSopaLetrasJson";
import RandomNumberInRange from "./api/RandomNumberInRange";
import wordsSearchData from "./jsonData/wordsSearchData.json";

const SopaLetrasJson = () => {
  const size = wordsSearchData.data.length;
  const chosenItemNum = RandomNumberInRange(0, size - 1);
  const chosenWordsSearch = wordsSearchData.data[chosenItemNum];

  return (
    <React.Fragment>
      <BuildSopaLetrasJson chosenWordsSearch={chosenWordsSearch} />
    </React.Fragment>
  );
};

export default SopaLetrasJson;
