import React from "react";
import BuildCrosswordJson from "./BuildCrosswordJson";
import RandomNumberInRange from "./api/RandomNumberInRange";
import crosswordData from "./jsonData/crosswordsData.json";

const BoardElementsJson = () => {
  // Size of the crosswordData.data
  const size = crosswordData.data.length;
  const chosenItemNum = RandomNumberInRange(0, size - 1);
  const chosenCrossword = crosswordData.data[chosenItemNum];

  return (
    <React.Fragment>
      <BuildCrosswordJson chosenCrossword={chosenCrossword} />
    </React.Fragment>
  );
};

export default BoardElementsJson;
