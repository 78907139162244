import React from "react";
import SopaLetras from "./sopaletras/SopaLetras";
import SopaLetrasJson from "./sopaletras/SopaLetrasJson";

const WordsSearch = () => {
  return (
    <div className="wordsSearchWrapper">
      <div className="wordsSearchLeft">
        {/*<SopaLetras />*/}
        {/*<SopaLetrasJson />*/}
        <SopaLetrasJson />
      </div>
      <div className="wordsSearchRight">
        <div className="wordsSearchRightAvatar"></div>
        <div className="wordsSearchRightDesc">
          <p>"Over 1 000 000 Word Search Boards"</p>
          <p>Play On Line or Print the Board</p>
        </div>
      </div>
    </div>
  );
};

export default WordsSearch;
