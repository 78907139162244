// import { PDFDownloadLink } from "@react-pdf/renderer";
import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Footer from "./components/Footer";

import Navbar from "./components/navbar/Navbar";
//import PDFDoc from "./components/PDFDoc";
import About from "./components/About";
import Contact from "./components/Contact";
import Home from "./components/Home";

const App = () => {
  if (window.matchMedia("(max-width: 768px)").matches) {
    // The viewport is less than 768 pixels wide
    return (
      <DndProvider backend={TouchBackend}>
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/contact" element={<Contact />} />
          </Routes>
        </BrowserRouter>
        <Footer />
        {/*}
        <PDFDownloadLink document={<PDFDoc />} filename="FORM">
          {({ loading }) =>
            loading ? (
              <button>Preparing File...</button>
            ) : (
              <button>Download</button>
            )
          }
        </PDFDownloadLink>
        {*/}
      </DndProvider>
    );
  } else {
    return (
      <DndProvider backend={HTML5Backend}>
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/contact" element={<Contact />} />
          </Routes>
        </BrowserRouter>
        <Footer />
        {/*}
        <PDFDownloadLink document={<PDFDoc />} filename="FORM">
          {({ loading }) =>
            loading ? (
              <button>Preparing File...</button>
            ) : (
              <button>Download</button>
            )
          }
        </PDFDownloadLink>
        {*/}
      </DndProvider>
    );
  }
};

export default App;
