import React from "react";

const Animation = () => {
  return (
    <div className="wrapper">
      <div className="cells">
        <div className="chars">
          <b>J</b>
        </div>
      </div>
      <div className="cells">
        <div className="chars">
          <b>U</b>
        </div>
      </div>
      <div className="cells">
        <div className="chars">
          <b>S</b>
        </div>
      </div>
      <div className="cells">
        <div className="chars">
          <b>T</b>
        </div>
      </div>
      <div className="cells" style={{ backgroundColor: "#28282b" }}>
        <b></b>
      </div>
      <div className="cells" style={{ backgroundColor: "#28282b" }}>
        <b></b>
      </div>
      <div className="cells">
        <div className="chars">
          <div className="chars">
            <b>P</b>
          </div>
        </div>
      </div>
      <div className="cells">
        <div className="chars">
          <b>L</b>
        </div>
      </div>
      <div className="cells">
        <div className="chars">
          <b>A</b>
        </div>
      </div>
      <div className="cells">
        <div className="chars">
          <b>Y</b>
        </div>
      </div>
      <div className="cells" style={{ backgroundColor: "#28282b" }}>
        <b></b>
      </div>
      <div className="cells">
        <div className="chars">
          <div className="chars">
            <b>A</b>
          </div>
        </div>
      </div>
      <div className="cells">
        <div className="chars">
          <b>N</b>
        </div>
      </div>
      <div className="cells">
        <div className="chars">
          <b>D</b>
        </div>
      </div>
      <div className="cells" style={{ backgroundColor: "#28282b" }}>
        <b></b>
      </div>
      <div className="cells">
        <div className="chars">
          <div className="chars">
            <b>L</b>
          </div>
        </div>
      </div>
      <div className="cells">
        <div className="chars">
          <b>E</b>
        </div>
      </div>
      <div className="cells">
        <div className="chars">
          <b>A</b>
        </div>
      </div>
      <div className="cells">
        <div className="chars">
          <b>R</b>
        </div>
      </div>
      <div className="cells">
        <div className="chars">
          <b>N</b>
        </div>
      </div>
    </div>
  );
};

export default Animation;
