import React from "react";
import "../App.css";
import Animation from "./animation/Animation";
import "./animation/animation.css";
import BoardElements from "./crossword/BoardElements";
import BoardElementsJson from "./crossword/BoardElementsJson";

const Main = () => {
  return (
    <div className="mainWrapper">
      <div className="mainLeft">
        {/*<BoardElements />*/}
        {/*<BoardElementsJson />*/}
        <BoardElementsJson />
      </div>
      <div className="mainRight">
        <div className="mainRightDesc">
          <h2>"Over 1,000,000 Word Games to Improve Your Vocabulary Skills"</h2>
          <span>
            <Animation />
          </span>
          <h3
            style={{
              margin: "0 auto",
              textAlign: "center",
              marginTop: "60px",
            }}
          >
            Crosswords & Word Search Puzzles
          </h3>
          <p>Play On line or Print the Boards !</p>
        </div>
      </div>
    </div>
  );
};

export default Main;
