import update from "immutability-helper";
import React, { useCallback, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";

import { ItemTypes } from "./dnd/ItemTypes.js";
import { WordHome } from "./dnd/WordHome.js";
import { WordLabel } from "./dnd/WordLabel.js";
import "./dnd/dnd.css";

import PrintIcon from "@mui/icons-material/Print";
import { typographyClasses } from "@mui/material";
import Grid from "@mui/material/Grid";
import AcrossFlags from "./boardElements/AcrossFlags";
import DownFlags from "./boardElements/DownFlags";
import "./css/crossword_style.css";

const BuildCrosswordJson = (props) => {
  const [solutionBoard, setSolutionBoard] = useState("none");
  const [solutionButtonTxt, setSolutionButtonTxt] = useState("Solution");
  const [solutionButtonColor, setSolutionButtonColor] = useState("yellow");

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Document Title",
  });

  const wordHomesArray = [];
  //boardSize of the board is rows_num * cols
  // IMPORTANT: The board size has to be updated here manually with the size of the crossword board,otherwise
  // the dnd does not work.
  const boardSize = 25;

  for (var j = 0; j < boardSize; j++) {
    wordHomesArray.push({
      text: "",
      accepts: [
        ItemTypes.A,
        ItemTypes.B,
        ItemTypes.C,
        ItemTypes.D,
        ItemTypes.E,
        ItemTypes.F,
        ItemTypes.G,
        ItemTypes.H,
        ItemTypes.I,
        ItemTypes.J,
        ItemTypes.K,
        ItemTypes.L,
        ItemTypes.M,
        ItemTypes.N,
        ItemTypes.O,
        ItemTypes.P,
        ItemTypes.Q,
        ItemTypes.R,
        ItemTypes.S,
        ItemTypes.T,
        ItemTypes.U,
        ItemTypes.V,
        ItemTypes.W,
        ItemTypes.X,
        ItemTypes.Y,
        ItemTypes.Z,
      ],
      lastDroppedItem: null,
    });
  }

  const [wordHomes, setWordHomes] = useState(wordHomesArray);

  const [wordLabels] = useState([
    { name: "A", type: ItemTypes.A },
    { name: "B", type: ItemTypes.B },
    { name: "C", type: ItemTypes.C },
    { name: "D", type: ItemTypes.D },
    { name: "E", type: ItemTypes.E },
    { name: "F", type: ItemTypes.F },
    { name: "G", type: ItemTypes.G },
    { name: "H", type: ItemTypes.H },
    { name: "I", type: ItemTypes.I },
    { name: "J", type: ItemTypes.J },
    { name: "K", type: ItemTypes.K },
    { name: "L", type: ItemTypes.L },
    { name: "M", type: ItemTypes.M },
    { name: "N", type: ItemTypes.N },
    { name: "O", type: ItemTypes.O },
    { name: "P", type: ItemTypes.P },
    { name: "Q", type: ItemTypes.Q },
    { name: "R", type: ItemTypes.R },
    { name: "S", type: ItemTypes.S },
    { name: "T", type: ItemTypes.T },
    { name: "U", type: ItemTypes.U },
    { name: "V", type: ItemTypes.V },
    { name: "W", type: ItemTypes.W },
    { name: "X", type: ItemTypes.X },
    { name: "Y", type: ItemTypes.Y },
    { name: "Z", type: ItemTypes.Z },
  ]);

  // const [droppedWordLabelNames, setDroppedWordLabelNames] = useState([]);

  const handleDrop = useCallback(
    (index, item) => {
      const { name } = item;
      /*setDroppedWordLabelNames(name);*/
      setWordHomes(
        update(wordHomes, {
          [index]: {
            lastDroppedItem: {
              $set: name,
            },
          },
        })
      );
    },
    [/*droppedWordLabelNames,*/ wordHomes]
  );

  const rows = Number(props.chosenCrossword.rows_num);
  const cols = Number(props.chosenCrossword.cols);
  const crosswordString = props.chosenCrossword.final_board;
  const across = props.chosenCrossword.across_defs.replaceAll("^", '"');
  const down = props.chosenCrossword.down_defs.replaceAll("^", '"');

  // cellWidth should be equal to width in dnd.css .wordHome class
  const cellWidth = 50;
  const cellHeight = cellWidth;
  const BLACK = "*";
  const WHITE = " ";
  const addWidth = 2 * cols;
  const boardWidth = cellWidth * cols + addWidth;
  const boardHeight = cellHeight * rows + addWidth;
  const gridWidth = boardWidth + 60;

  const acrossFlags = AcrossFlags(rows, cols, BLACK, crosswordString);
  const downFlags = DownFlags(rows, cols, BLACK, crosswordString);

  let crosswordArray = [];
  let crosswordBoardArray = [];
  let startingCrosswordBoard = [];
  var indexH = 1;
  var indexV = 1;

  for (var i = 0; i < crosswordString.length; i++) {
    // Crossword Solution array
    if (crosswordString[i] === BLACK) {
      crosswordArray.push([BLACK, "#28282b"]);
    } else {
      crosswordArray.push([crosswordString[i], "transparent"]);
    }

    // Crossword Board array
    if (crosswordString[i] === BLACK) {
      crosswordBoardArray.push([BLACK, "#28282b"]);
    } else {
      crosswordBoardArray.push([WHITE, "transparent"]);
    }

    // Starting Crossword Board
    if (crosswordString[i] === BLACK) {
      startingCrosswordBoard.push([BLACK, "#28282b", " ", " ", "whiteSmoke"]);
    } else {
      if (acrossFlags.includes(i) && downFlags.includes(i)) {
        startingCrosswordBoard.push([
          crosswordString[i],
          "#fff",
          indexH + "→",
          indexV + "↓",
          "#fff",
        ]);
        indexH++;
        indexV++;
      } else if (acrossFlags.includes(i)) {
        startingCrosswordBoard.push([
          crosswordString[i],
          "#fff",
          indexH + "→",
          " ",
          "#fff",
        ]);
        indexH++;
      } else if (downFlags.includes(i)) {
        startingCrosswordBoard.push([
          crosswordString[i],
          "#fff",
          " ",
          indexV + "↓",
          "#fff",
        ]);
        indexV++;
      } else {
        startingCrosswordBoard.push([
          crosswordString[i],
          "#fff",
          " ",
          " ",
          "#fff",
        ]);
      }
    }
  }

  function handleCrosswordSolutionOnClick(e) {
    e.preventDefault();
    if (solutionBoard === "none") {
      setSolutionBoard("block");
      setSolutionButtonTxt("Close Solution");
      setSolutionButtonColor("gray");
    } else {
      setSolutionBoard("none");
      setSolutionButtonTxt("Solution");
      setSolutionButtonColor("yellow");
    }
  }

  return (
    <Grid
      className="crosswordElementsGrid"
      style={{
        width: `${gridWidth}px`,
      }}
    >
      <div
        className="buildCrosswordContainer"
        style={{
          width: `${boardWidth}px`,
          margin: "0 auto",
        }}
      >
        <div
          className="buildCrossword"
          style={{
            width: `${boardWidth}px`,
            margin: "0 auto",
          }}
        >
          {startingCrosswordBoard.map((boardItem, index) => (
            <div
              key={index}
              className="crosswordCells"
              style={{
                width: `${cellWidth}px`,
                height: `${cellHeight}px`,
                backgroundColor: boardItem[1],
              }}
            >
              <div className="acrossFlags">{boardItem[2]}</div>
              <div className="downFlags">{boardItem[3]}</div>
            </div>
          ))}
        </div>
        <div className="copyright">kobikibu©</div>

        <div
          className="wordHomeWrapper"
          style={{
            zIndex: "5000",
            width: `${boardWidth}px`,
            height: `${boardHeight}px`,
            margin: "0 auto",
          }}
        >
          {wordHomes.map(({ accepts, lastDroppedItem, text }, index) => (
            <WordHome
              accept={accepts}
              lastDroppedItem={lastDroppedItem}
              text={text}
              onDrop={(item) => handleDrop(index, item)}
              key={index}
            />
          ))}
        </div>
        <div className="wordLabelWrapper">
          {wordLabels.map(({ name, type }, index) => (
            <WordLabel name={name} type={type} key={index} />
          ))}
        </div>
        <div className="wordDefinitions">
          <b>Across :</b>
          <p>{across}</p>
        </div>
        <div className="wordDefinitions">
          <b>Down :</b>
          <p>{down}</p>
        </div>
        <div className="crosswordSolution">
          <button
            onClick={handleCrosswordSolutionOnClick}
            style={{
              backgroundColor: `${solutionButtonColor}`,
            }}
          >
            <b>{solutionButtonTxt}</b>
          </button>
          <div style={{ display: `${solutionBoard}` }}>
            <div
              className="buildCrossword"
              style={{
                width: `${boardWidth}px`,
                margin: "0 auto",
                marginTop: "1em",
              }}
            >
              {startingCrosswordBoard.map((boardItem, index) => (
                <div
                  key={index}
                  className="crosswordCells"
                  style={{
                    width: `${cellWidth}px`,
                    height: `${cellHeight}px`,
                    backgroundColor: boardItem[1],
                    textAlign: "center",
                    lineHeight: `${cellHeight}px`,
                    verticalAlign: "middle",
                    color: "#28282b",
                  }}
                >
                  <b>{boardItem[0]}</b>
                </div>
              ))}
            </div>
            <div className="copyright">kobikibu©</div>
          </div>
        </div>
      </div>
      <button style={{ margin: "0 auto" }} onClick={handlePrint}>
        <PrintIcon />
      </button>

      <div style={{ display: "none" }}>
        <div
          className="buildCrosswordContainer"
          ref={componentRef}
          style={{
            width: `${boardWidth}px`,
            margin: "50px auto",
            height: "90vh",
            position: "relative",
          }}
        >
          <h1
            style={{
              textAlign: "center",
              margin: "20px auto",
              width: "100%",
            }}
          >
            Crossword
          </h1>
          <div
            className="buildCrossword"
            style={{
              width: `${boardWidth}px`,
              height: `${boardHeight}px`,
              margin: "0 auto",
            }}
          >
            {startingCrosswordBoard.map((boardItem, index) => (
              <div
                key={index}
                className="crosswordCells"
                style={{
                  width: `${cellWidth}px`,
                  height: `${cellHeight}px`,
                  backgroundColor: boardItem[4],
                }}
              >
                <div className="acrossFlags">{boardItem[2]}</div>
                <div className="downFlags">{boardItem[3]}</div>
              </div>
            ))}
          </div>
          <div className="copyright" style={{ color: "#000" }}>
            kobikibu©
          </div>

          <div className="wordDefinitions" style={{ color: "#000" }}>
            <b>Across :</b>
            <p>{across}</p>
          </div>
          <div className="wordDefinitions" style={{ color: "#000" }}>
            <b>Down :</b>
            <p>{down}</p>
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "0",
              left: "40px",
              color: "#000",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span
                style={{ textAlign: "center", color: "#000", fontSize: "12px" }}
              >
                Created by Richard Iriondo
              </span>

              <span
                style={{ textAlign: "center", color: "#000", fontSize: "12px" }}
              >
                www.richardiriondo.com
              </span>
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default BuildCrosswordJson;
