import Grid from "@mui/material/Grid";
import React, { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import "./css/sopaletras.css";

import PrintIcon from "@mui/icons-material/Print";

const BuildSopaLetrasJson = (props) => {
  let initialCellBackgrounds = [];
  // Using 1000 as the limit for i we cover allways all cases.
  for (let i = 0; i < 1000; i++) {
    initialCellBackgrounds.push({ id: i, value: "transparent" });
  }

  const [solutionBoard, setSolutionBoard] = useState("none");
  const [solutionButtonTxt, setSolutionButtonTxt] = useState("Solution");
  const [solutionButtonColor, setSolutionButtonColor] = useState("gold");
  const [cellBackgroundColor, setCellBackgroundColor] = useState(
    initialCellBackgrounds
  );

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Document Title",
  });

  /*

  let initialDisplayTranslation = [];
  // Using 30 as the limit for i we cover always all cases.
  for (let i = 0; i < 30; i++) {
    initialDisplayTranslation.push({ id: i, value: "none" });
  }

  const [displayTranslation, setDisplayTranslation] = useState(
    initialDisplayTranslation
  );

  */

  const sopa_de_letra = props.chosenWordsSearch.sopa_de_letra;
  const palabras = props.chosenWordsSearch.palabras;
  const side = Number(props.chosenWordsSearch.side);
  const translatedPalabras = props.chosenWordsSearch.translated_palabras;
  const solutionBoardIndexes = props.chosenWordsSearch.solution_board_indexes;

  const cellSize = 30;
  const boardWith = side * cellSize;
  const boardWrapper = boardWith + 70;

  let sopaLetrasArray = [];
  for (let i = 0; i < sopa_de_letra.length; i++) {
    sopaLetrasArray.push(sopa_de_letra[i]);
  }

  let palabrasArray = [];
  let oneWord = "";
  for (let i = 0; i < palabras.length; i++) {
    oneWord = oneWord.concat(palabras[i]);
    if (palabras[i] === "*") {
      oneWord = oneWord.substring(0, oneWord.length - 1);
      palabrasArray.push(oneWord);
      oneWord = "";
    }
  }

  let translatedPalabrasArray = [];
  let oneWordTranslated = "";
  for (let i = 0; i < translatedPalabras.length; i++) {
    oneWordTranslated = oneWordTranslated.concat(translatedPalabras[i]);
    if (translatedPalabras[i] === "*") {
      oneWordTranslated = oneWordTranslated.substring(
        0,
        oneWordTranslated.length - 1
      );
      translatedPalabrasArray.push(oneWordTranslated);
      oneWordTranslated = "";
    }
  }

  let allPalabras = [];
  for (let i = 0; i < translatedPalabrasArray.length; i++) {
    allPalabras.push([palabrasArray[i], translatedPalabrasArray[i]]);
  }

  let solutionBoardIndexesArray = [];
  let oneIndex = "";
  for (let i = 0; i < solutionBoardIndexes.length; i++) {
    oneIndex = oneIndex.concat(solutionBoardIndexes[i]);
    if (solutionBoardIndexes[i] === "*") {
      oneIndex = oneIndex.substring(0, oneIndex.length - 1);
      solutionBoardIndexesArray.push(parseInt(oneIndex));
      oneIndex = "";
    }
  }

  let sopaLetrasSolutionArray = [];
  for (let i = 0; i < sopa_de_letra.length; i++) {
    if (solutionBoardIndexesArray.includes(i)) {
      sopaLetrasSolutionArray.push([sopa_de_letra[i], "gold"]);
    } else {
      sopaLetrasSolutionArray.push([sopa_de_letra[i], "transparent"]);
    }
  }

  function handleCrosswordSolutionOnClick(e) {
    e.preventDefault();
    if (solutionBoard === "none") {
      setSolutionBoard("block");
      setSolutionButtonTxt("Close Solution");
      setSolutionButtonColor("lemonChiffon");
    } else {
      setSolutionBoard("none");
      setSolutionButtonTxt("Solution");
      setSolutionButtonColor("gold");
    }
  }

  return (
    <Grid className="sopaLetrasGrid" style={{ width: `${boardWrapper}px` }}>
      <div
        className="sopaLetrasBoard"
        style={{ width: `${boardWith}px`, height: `${boardWith}px` }}
      >
        {sopaLetrasArray.map((char, charIndex) => (
          <div key={charIndex}>
            <button
              key={charIndex}
              onClick={function (e) {
                e.preventDefault();
                const newCellBackgrounds = cellBackgroundColor.map(
                  (cellData, indexKey) => {
                    if (indexKey === charIndex) {
                      if (cellData.value === "transparent") {
                        cellData.value = "gold";
                      } else {
                        cellData.value = "transparent";
                      }
                    }
                    return cellData;
                  }
                );
                setCellBackgroundColor(newCellBackgrounds);
              }}
              style={{
                backgroundColor: `${cellBackgroundColor[charIndex].value}`,
                width: `${cellSize}px`,
                height: `${cellSize}px`,
                lineHeight: `${cellSize}px`,
                border: "1px dashed lightGray",
              }}
            >
              <b>{char}</b>
            </button>
          </div>
        ))}
      </div>

      <div className="copyright">
        <b>kobikibu©</b>
      </div>
      <div className="wordsList">
        {allPalabras.map((word, wordIndex) => (
          <button
            key={wordIndex}
            /*
            onClick={function (e) {
              e.preventDefault();
              const newDisplayTranslation = displayTranslation.map(
                (item, indexItem) => {
                  if (indexItem === wordIndex) {
                    if (item.value === "none") {
                      item.value = "block";
                    } else {
                      item.value = "none";
                    }
                  }
                  return item;
                }
              );
              setDisplayTranslation(newDisplayTranslation);
            }}
      */
          >
            {word[0]}
            {/*}
            <span style={{ display: `${displayTranslation[wordIndex].value}` }}>
              =<br></br>
              {word[1]}
            </span>
            */}
          </button>
        ))}
      </div>
      <div className="solutionButton">
        <button
          onClick={handleCrosswordSolutionOnClick}
          style={{ backgroundColor: `${solutionButtonColor}` }}
        >
          <b>{solutionButtonTxt}</b>
        </button>
      </div>
      <button style={{ margin: "0 auto" }} onClick={handlePrint}>
        <PrintIcon />
      </button>
      <div
        className="sopaLetrasBoardSolution"
        style={{ display: `${solutionBoard}` }}
      >
        <div
          className="sopaLetrasBoard"
          style={{
            marginTop: "1em",
            width: `${boardWith}px`,
            height: `${boardWith}px`,
          }}
        >
          {sopaLetrasSolutionArray.map((char, charIndex) => (
            <div key={charIndex}>
              <button
                key={charIndex}
                style={{
                  backgroundColor: `${char[1]}`,
                  width: `${cellSize}px`,
                  height: `${cellSize}px`,
                  lineHeight: `${cellSize}px`,
                  border: "1px dashed lightGray",
                }}
              >
                <b>{char[0]}</b>
              </button>
            </div>
          ))}
        </div>
        <div className="copyright">
          <b>kobikibu©</b>
        </div>
      </div>

      <div style={{ display: "none" }}>
        <div className="sopaLetrasPrintWrapper" ref={componentRef}>
          <h1
            style={{
              textAlign: "center",
              margin: "40px auto",
              width: "100%",
            }}
          >
            Words Search
          </h1>
          <div
            className="sopaLetrasBoard"
            style={{
              width: `${boardWith}px`,
              height: `${boardWith}px`,
            }}
          >
            {sopaLetrasArray.map((char, charIndex) => (
              <div key={charIndex}>
                <button
                  key={charIndex}
                  style={{
                    backgroundColor: `${cellBackgroundColor[charIndex].value}`,
                    width: `${cellSize}px`,
                    height: `${cellSize}px`,
                    lineHeight: `${cellSize}px`,
                    border: "1px dashed lightGray",
                  }}
                >
                  <b>{char}</b>
                </button>
              </div>
            ))}
          </div>

          <div className="copyright" style={{ color: "#000" }}>
            <b>kobikibu©</b>
          </div>
          <div
            className="wordsList"
            style={{
              color: "#000",
              width: "60%",
            }}
          >
            {allPalabras.map((word, wordIndex) => (
              <p
                key={wordIndex}
                style={{
                  padding: "5px",
                  border: "1px solid lightGray",
                }}
              >
                {word[0]}
              </p>
            ))}
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              style={{ textAlign: "center", color: "#000", fontSize: "12px" }}
            >
              Created by Richard Iriondo
            </span>

            <span
              style={{ textAlign: "center", color: "#000", fontSize: "12px" }}
            >
              www.richardiriondo.com
            </span>
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default BuildSopaLetrasJson;
